import React from 'react';
import { useState, useEffect } from 'react';

const EmailComponent = ({ onInputEmail, email }) => {
  //   const [email, setEmail] = useState('');
  //   const inputHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
  //     setEmail(e.target.value);
  //   };
  // console.log(email)
  return (
    <>
      <div className="w-full pb-3">
        <label
          htmlFor="Email"
          className="pb-1 font-semibold text-base"
        >
          Email
        </label>
        <br />
        <input
          type="email"
          placeholder="Enter your email"
          name="email"
          value={email}
          onInput={onInputEmail}
          className="border-2 w-full px-4 py-3 border-border rounded-lg outline-0"
        ></input>
      </div>
    </>
  );
};

export default EmailComponent;
