import React, { useEffect, useState } from 'react';

import eye from 'assets/images/auth/fi_eye.svg';
import RecreateLeft from '../common/auth/recreateLeftComponent';
import EmailComponent from '../common/auth/emailComponent';
import { Link } from 'react-router-dom';

const ResetPasswordEmail = () => {
  const [email, setemail] = useState('');
  // const [password, setpassword] = useState('');

  const onInputEmail = e => setemail(e.target.value);
  // const onInputPassword = e => setpassword(e.target.value);
  return (
    <>
      <div className="w-full h-screen flex font-Outfit">
        {/* <div className="w-full "> */}
        {/* <RecreateLeft /> */}
        <div className="w-full flex flex-col items-center justify-center">
          <div className="w-1/4 pt-10">
            <div className="sign-in flex flex-col items-center gap-y-3">
              <p className="text-3xl font-black">Reset Password</p>
              <p className="border-amber-400 border-2 w-14 rounded-lg"></p>
              <p className="text-paragraph font-normal text-base">
                Please enter the details below to continue.
              </p>
            </div>
            <form className="py-5 ">
              <EmailComponent onInputEmail={onInputEmail} email={email} />
              <a
                //   href="https://2dph6qylk59.typeform.com/to/ObGGRwOt"
                target="_blank"
              >
                <Link to="/resetpassword">
                  {' '}
                  <button
                    className={` border-2 w-full py-3 border-border rounded-lg text-white font-semibold
                    ${email
                        ? 'opacity-1 bg-amber-400 border-amber-400'
                        : 'bg-amber-400 opacity-[0.5] border-amber-400'
                      } `}
                    // hover:opacity-100 hover:bg-button1
                    disabled={!email}
                  >
                    Send Email
                  </button>
                </Link>
              </a>
              <div className="py-4 text-center">
                <p className="text-sm">
                  Didn’t receive the link?{' '}
                  <Link to="/login">
                    {' '}
                    <span className="">Resend Email</span>
                  </Link>
                </p>
              </div>
            </form>
            <div></div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default ResetPasswordEmail;
