import Login from 'app/components/Login';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Routes, Route } from 'react-router-dom';

export function LoginPage() {
  return (
    <>
      <Helmet>
        <title>ffMapp | Login </title>
        <meta
          name="description"
          content="A React Boilerplate application homepage"
        />
      </Helmet>
      <div>
        <Login />
      </div>
    </>
  );
}
